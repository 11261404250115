(function() {
    'use strict';

    angular.module("mfgApp")
        .controller("ManageLicensesCtrl", manageLicensesController);

    manageLicensesController.$inject = ["$scope", "mfgApi", "licenseFilterTypes", "Notification", "$http",
        "routesConstant"];

    function manageLicensesController($scope, mfgApi, licenseFilterTypes, Notification, $http, routesConstant) {
        $scope.$parent.page = $scope.$parent.page || {};
        $scope.$parent.page.title = "Manage aeRos Pro Licenses";
        $scope.MFG = routesConstant.MFG;

        var url = "/admin/api/userprofile";
        $http.get(url).success(function(data) {
            $scope.me = data;
        });

        // set up ag-grid
        $scope.selectedForExport = [];
        $scope.gridOptions = {
            columnDefs: [],
            rowData: [],
            enableSorting: true,
            enableFilter: true,
            rowSelection: 'multiple',
            enableColResize: true,
            angularCompileRows: true,
            onSelectionChanged: function () {
                $scope.selectedForExport = _.map($scope.gridOptions.api.getSelectedNodes(), function (node) {
                    return node.data;
                });
                console.log($scope.selectedForExport);
            }
        };
        var licenseFields = [
            "id",						// GUID ("licenseKey" in filter)

            "customerName",			// string (if exists)

            //"createDate",			// epoch
            "createDateFormatted",	// "2016-03-30 09:04:47.000840"
            "createdBy",

            "term",					// "1 month"
            //"termCode",				// "MONTH"

            "status",					// ACTIVE / INACTIVE
            //"active",				// bool

            'orgAdmin',				// (email) assignee (if exists)
            //"assigned",				// bool

            "exported",				// bool
            "delete"
            //"type",					// ? always "TENUSERS"
            //"allowedUsers",			// num (10)
            //"assignedUsers",		// num (1)
            //"openSeats",			// num (9)

        ];
        _.each(licenseFields, function (field) {
            var headerConfig = {
                headerName: field,
                field: field
            };
            if (field === "id") {
                headerConfig.pinned = true;
                headerConfig.checkboxSelection = true;
            }
            if (field === "delete") {
                headerConfig.cellRenderer = function(params)  {
                    return '<a ng-click="deleteLicense(data)" ng-if="canDelete(data)">Delete</a>';
                };
                headerConfig.displayName="Delete";
            }
            $scope.gridOptions.columnDefs.push(headerConfig);
        });

        $scope.canDelete = function (data) {
            return data.createdBy == $scope.me.user.username && data.status == "INACTIVE" && data.assigned == false
                && data.exported == false && data.assignedUsers == 0 && data.active == false ;
        };

        $scope.deleteLicense = function(data){
            var config = {headers:  {
                    'Accept': 'application/json;'
                }
            };
            $http.delete('/admin/license/' + data.id, config)
                .success(function (data, status, headers) {
                    $scope.filterApi.submit();
                })
                .error(function (data, status, header, config) {
                });
        };
        function resetFilters () {
            $scope.selectedForExport = [];
            $scope.tempFilterVals = {}; // used to bind values that need to be processed before query
            $scope.filterObj = {};
            $scope.filterById = {};
            $scope.filters = _.filter(licenseFilterTypes, function (filter) {
                if (_.includes(filter.allow, 'mfg')) {
                    var filterModel = "";
                    switch(filter.type) {
                        case 'NUM':
                            break;
                        case 'BOOL':
                            filterModel = {value: ""};
                            break;
                        case 'STRING':
                            filterModel = {values: []};
                            break;
                        case 'DATE':
                            filterModel = {range: {start: "", end: ""}};
                            $scope.tempFilterVals[filter.id] = {dateType: "On", main: "", range: ""};
                            break;
                        default:
                            break;
                    }
                    $scope.filterObj[filter.id] = filterModel;
                    $scope.filterById[filter.id] = filter;
                    return true;
                }
                return false;
            });
        }
        var payloadDateFormat = "MM/DD/YYYY";
        function filterIsSet (filterId, val) {
            var filterType = $scope.filterById[filterId].type;
            var isSet = false;
            switch(filterType) {
                case 'NUM':
                    if (val) {
                        isSet = true;
                    }
                    break;
                case 'BOOL':
                    if (['true','false'].indexOf(val.value)>-1) {
                        isSet = true;
                    }
                    break;
                case 'STRING':
                    if (val.values.length) {
                        isSet = true;
                    }
                    break;
                case 'DATE':
                    if ($scope.tempFilterVals[filterId].main instanceof Date) {
                        isSet = true;
                        var mainDate = moment($scope.tempFilterVals[filterId].main).format(payloadDateFormat);
                        switch($scope.tempFilterVals[filterId].dateType) {
                            case 'On':
                                $scope.tempFilterVals[filterId].payloadObj = {value: mainDate};
                                break;
                            case 'On or Before':
                                $scope.tempFilterVals[filterId].payloadObj = {range: {end: mainDate}};
                                break;
                            case 'On or After':
                                $scope.tempFilterVals[filterId].payloadObj = {range: {start: mainDate}};
                                break;
                            case 'Between':
                                if ($scope.tempFilterVals[filterId].range instanceof Date) {
                                    var rangeDate =  moment($scope.tempFilterVals[filterId].range).format(payloadDateFormat);
                                    $scope.tempFilterVals[filterId].payloadObj = {range: {start: mainDate, end: rangeDate}};
                                } else {
                                    isSet = false;
                                }
                                break;
                        }
                    }
                    break;
                default:
                    break;
            }
            return isSet;
        }
        $scope.filterApi = {
            clear: function () {
                resetFilters();
                $scope.showResults = false;
                $scope.gridOptions.api.setRowData([]);
            },
            addToCollection: function (filter, value) {
                if (!$scope.tempFilterVals[filter.id]) { return; }
                $scope.filterObj[filter.id].values.push(value);
                $scope.tempFilterVals[filter.id] = "";
            },
            removeFromCollection: function (filter, index) {
                $scope.filterObj[filter.id].values.splice(index, 1);
            },
            submit: function () {
                Notification.success("Sending Request");
                $scope.showResults = false;
                var payload = {};
                _.each($scope.filterObj, function (val, key) {
                    if (filterIsSet(key, val)) {
                        if ( $scope.filterById[key].type === "DATE" ) {
                            payload[key] = $scope.tempFilterVals[key].payloadObj;
                        } else {
                            payload[key] = val;
                        }
                    }
                });
                $scope.gridOptions.api.setRowData(null);
                mfgApi.getFilteredLicensesList(payload)
                    .success(function (data) {
                        Notification.success("Processing Results");
                        $scope.gridOptions.api.setRowData(data.results);
                        $scope.showResults = true;
                    })
                    .error(function (err) {
                        Notification.success("Error Processing Results");
                        console.log("error", err);
                    });
            }
        };

        $scope.dateTypes = [
            'On',
            'On or Before',
            'On or After',
            'Between'
        ];

        $scope.predefinedList = {};
        function getTerms() {
            mfgApi.getLicenseTerms().success(function (data) {
                var terms = data.licenseTerms;
                $scope.predefinedList['licenseTerm'] = [];
                _.each(terms, function (term) {
                    $scope.predefinedList['licenseTerm'].push(term);
                });
            });
        }

        $scope.exportLicenses = function () {
            var ids = _.map($scope.selectedForExport, 'id');

            $scope.exportUrl=undefined;
            console.log("I will print these licenses: ");
            console.log(ids);
            mfgApi.exportLicenses(ids).
            success(function(data) {
                //Get file name from data
                //import the file as a link now...
                $scope.exportUrl = data.url;
                console.log("export file "+ $scope.exportUrl);
            }).error(function (err) {
                $scope.exportUrl=undefined;
                Notification.error("An error occurred generating the export. " + err.message);
            });

        };

        $scope.downloadLicenses = function(exportUrl) {
            mfgApi.downloadLicenses(exportUrl)
            .success(function (data) {
                window.location = data.downloadUrl;
            })
            .error(function (err) {
                Notification.error("An error occurred exporting the export. " + err.message);
            });
        };

        (function init() {
            getTerms();
            resetFilters();
        }() );

    }
})();

